<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full">
      <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">
        <div class="container flex justify-center">
          <div class="flex flex-wrap justify-start w-full">
            <div class="cursor-pointer flex justify-start items-center site-logo" v-if="pageBranding && pageBranding.isHPP" style="max-height: 125px">
              <S3ImageGenerator v-if="pageBranding.merchantLogo" :document="pageBranding.merchantLogo" :custom-class="''" :is-base64="false" :key="pageBranding.merchantLogo"/>
              <div class="logo-divider flex items-center" v-if="pageBranding.cobrandLogo">
              <S3ImageGenerator v-if="pageBranding.cobrandLogo" :document="pageBranding.cobrandLogo" :custom-class="''" :is-base64="false" :key="pageBranding.cobrandLogo"/>
              </div>
            </div>
            <div class="cursor-pointer flex justify-start items-center site-logo" v-else-if="Object.keys(pageDetail).length" style="max-height: 125px">
              <template v-if="checkLogoInArray">
                <img v-if="logoImage" :src="logoImage" alt="img" class="responsive"/>
                <div class="logo-divider flex items-center" v-if="cobrandLogo">&nbsp;</div>
                <img v-if="cobrandLogo" :src="cobrandLogo" alt="img" class="responsive"/>
              </template>
              <template v-else>
                <S3ImageGenerator v-if="logoImage" :document="logoImage" :custom-class="''" :is-base64="!(Object.keys(pageDetail).length && pageDetail.logo)" :key="logoImage"/>
                <div class="logo-divider flex items-center" v-if="cobrandLogo">&nbsp;</div>
                <S3ImageGenerator v-if="cobrandLogo" :document="cobrandLogo" :custom-class="''" :is-base64="false" :key="cobrandLogo"/>
              </template>
            </div>
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
import { mapActions } from "vuex";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      prId: "",
      merchantLogoId: "",
      cobrandLogoId: "",
      logoImage: "",
      cobrandLogo: "",
      serverUrl: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    paymentRequestDetail: { type: Object },
    pageBranding: { type: Object },
    logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  components: {
    Bookmarks,
    S3ImageGenerator
  },
  methods: {
    ...mapActions("logo", ["fetchLogoByObjectId"]),

    formattedUrl(url) {
      if (!url) {
        return "";
      } else {
        return url.includes("http") ? url : `https://${url}`;
      }
    },
    async getLogoByObjectID(merchantLogoId) {
      await this.fetchLogoByObjectId(merchantLogoId).then((res) => {
        if (res.data.data.logoImage) {
          this.logoImage = res.data.data.logoImage;
        }
      });
    },

     async getCobrandLogoByObjectID(merchantLogoId) {
      await this.fetchLogoByObjectId(merchantLogoId).then((res) => {
        if (res.data.data.logoImage) {
          this.cobrandLogo = res.data.data.logoImage;
        }
      });
    },
  },

  created() {
    if(Object.keys(this.paymentRequestDetail).length > 0) {

      if (this.paymentRequestDetail.paymentPage.pageBranding && this.paymentRequestDetail.paymentPage.pageBranding.merchantLogo) {
        this.merchantLogoId = this.paymentRequestDetail.paymentPage.pageBranding.merchantLogo;
        this.getLogoByObjectID(this.merchantLogoId);
      }

      if (this.paymentRequestDetail.paymentPage.pageBranding && this.paymentRequestDetail.paymentPage.pageBranding.cobrandLogo) {
        this.cobrandLogoId = this.paymentRequestDetail.paymentPage.pageBranding.cobrandLogo;
        this.getCobrandLogoByObjectID(this.cobrandLogoId);
      }

      if (this.paymentRequestDetail.paymentPage.pageBranding.logos.length > 0) {
        this.logoImage = this.paymentRequestDetail.paymentPage.pageBranding.logos[0];

        if (this.paymentRequestDetail.paymentPage.pageBranding.logos.length >= 1) {
          this.cobrandLogo = this.paymentRequestDetail.paymentPage.pageBranding.logos[1];
        }
      }
    }
  },

  computed: {
    pageDetail() {
      return this.$store.state.payment.page ? this.$store.state.payment.page : {};
    },

    checkLogoInArray() {
      const paymentPage = this.paymentRequestDetail.paymentPage;
      return (paymentPage.pageBranding.logos && paymentPage.pageBranding.logos.length > 0);
    },

    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}
</style>