

<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage },
    ]"
  >
    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />

      <!-- Navbar -->
      <!-- <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200"> -->
      <template v-if="mainLayoutType === 'horizontal'">
        <the-navbar-horizontal
          v-if="navBarBind"
          :paymentRequestDetail="paymentRequestDetail"
          :pageBranding="pageBranding"
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="navMenuItems"
        />
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <div class="container">
              <transition :name="routerTransition">
                <div
                  v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                  class="router-header flex flex-wrap items-center"
                >
                  <div
                    class="content-area__heading"
                    :class="{
                      'pr-4 border-0 md:border-r border-solid border-grey-light':
                        $route.meta.breadcrumb,
                    }"
                  >
                    <h2 class="mb-1">{{ routeTitle }}</h2>
                  </div>
                </div>
              </transition>

              <div class="content-area__content">
                <back-to-top
                  bottom="5%"
                  :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                  visibleoffset="500"
                  v-if="!hideScrollToTop"
                >
                  <vs-button
                    icon-pack="feather"
                    icon="icon-arrow-up"
                    class="shadow-lg btn-back-to-top"
                  />
                </back-to-top>

                <transition :name="routerTransition" mode="out-in">
                  <router-view
                    @changeRouteTitle="changeRouteTitle"
                    @setAppClasses="
                      (classesStr) => $emit('setAppClasses', classesStr)
                    "
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-footer v-if="navBarBind" :pageBranding="pageBranding" />
    </div>
  </div>
</template>


<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/guest-components/horizontal-nav-menu/HorizontalNavMenu.vue";
// import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
import navMenuItems from "@/layouts/guest-components/vertical-nav-menu/navMenuItems.js";
import TheCustomizer from "@/layouts/guest-components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/layouts/guest-components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/guest-components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/guest-components/TheFooter.vue";
import themeConfig from "@/layouts/guest/themeConfig.js";
import VNavMenu from "@/layouts/guest-components/vertical-nav-menu/VerticalNavMenu.vue";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
  },
  data() {
    return {
      pageBranding: "",
      partnerId: "",
      brandSettings: "",
      navBarBind: false,
      paymentRequestDetail: {},
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: "hidden",
      navMenuItems: navMenuItems,
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
      prId: "",
      steps: [
        {
          target: "#btnVNavMenuMinToggler",
          content: "Toggle Collapse Sidebar.",
        },
        {
          target: ".vx-navbar__starred-pages",
          content:
            "Create your own bookmarks. You can also re-arrange them using drag & drop.",
        },
        {
          target: ".i18n-locale",
          content: "You can change language from here.",
        },
        {
          target: ".navbar-fuzzy-search",
          content: "Try fuzzy search to visit pages in flash.",
        },
        {
          target: ".customizer-btn",
          content: "Customize template based on your preference",
          params: {
            placement: "left",
          },
        },
        {
          target: ".vs-button.buy-now",
          content: "Buy this awesomeness at affordable price!",
          params: {
            placement: "top",
          },
        },
      ],
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor === "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"() {
      this.setNavMenuVisibility("horizontal");
      this.disableThemeTour = true;
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth === "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth === "reduced")
          return "content-area-lg";
        else return "content-area-full";
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType === "hidden",
        "footer-sticky": this.footerType === "sticky",
        "footer-static": this.footerType === "static",
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return "horizontal";
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType === "hidden",
        "navbar-sticky": this.navbarType === "sticky",
        "navbar-static": this.navbarType === "static",
        "navbar-floating": this.navbarType === "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    ...mapActions("payment", ["getPaymentDetails"]),
    ...mapActions("merchantGlobalSetting", [
      "fetchDefaultBrandByMerchantId",
      "fetchBrandByMerchantId",
      "fetchBrandingSettingsByMerchantId"
    ]),
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByWithCards",
      "fetchRequestTemplateById",
    ]),

    async getPRbyId() {
      await this.getPaymentDetails(this.prId).then((res) => {
        this.navBarBind = true;
        this.paymentRequestDetail = res.data.data.paymentDetails;
        this.pageBranding = this.paymentRequestDetail.paymentPage.pageBranding;
        this.partnerId = this.paymentRequestDetail.partnerId._id;
        this.getBrandInGlobalSettings(this.partnerId);
      });
    },

    async getDefaultBrandSettings() {
      await this.fetchDefaultBrandByMerchantId().then((res) => {
        this.brandSettings = res.data.data[0];
      });
    },

    async getBrandInGlobalSettings(partnerId) {
      await this.fetchBrandByMerchantId(partnerId).then((res) => {
        if (res.data.data.colorsAndFonts) {
          this.brandSettings = res.data.data[0];
        }
        if (!res.data.data.colorsAndFonts) {
          this.getDefaultBrandSettings();
        }
      });
    },

    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val === "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      this.isNavbarDark = val !== "#fff";
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
  created() {
    this.prId = this.$route.params.id || this.$route.params.paymentRequestId;
    let pageId = this.$route.params.pageId;
    if (this.prId) this.getPRbyId();
    if (pageId) {
      this.fetchRequestTemplateById(pageId).then((res) => {
        let dataToBind = res.data.data[0]
        this.navBarBind = true;
        let pageBrandingInfo = {};
        pageBrandingInfo.isHPP = true
        pageBrandingInfo.companyAbn = dataToBind && dataToBind.header && dataToBind.header.abn ? dataToBind.header.abn : "";
        pageBrandingInfo.companyAddress = dataToBind && dataToBind.header && dataToBind.header.address ? dataToBind.header.address : "";
        pageBrandingInfo.merchantLogo = dataToBind && dataToBind.header && dataToBind.header.companyLogo && dataToBind.header.companyLogo.logoImage ? dataToBind.header.companyLogo.logoImage : "";
        pageBrandingInfo.cobrandLogo = dataToBind && dataToBind.header && dataToBind.header.partnerLogo && dataToBind.header.partnerLogo.logoImage ? dataToBind.header.partnerLogo.logoImage : "";
        pageBrandingInfo.companyEmail = dataToBind && dataToBind.header && dataToBind.header.companyEmail ? dataToBind.header.companyEmail : "";
        pageBrandingInfo.companyName = dataToBind && dataToBind.header && dataToBind.header.companyName ? dataToBind.header.companyName : "";
        pageBrandingInfo.companyPhone = dataToBind && dataToBind.header && dataToBind.header.phone ? dataToBind.header.phone : "";
        pageBrandingInfo.companyWeb = dataToBind && dataToBind.header && dataToBind.header.website ? dataToBind.header.website : "";
        if(dataToBind.header.isDefaultHeader === true){
          this.fetchBrandingSettingsByMerchantId(dataToBind.merchantId).then((result) => {
            let defaultHeader = result.data.data.companyHeader;
            pageBrandingInfo.companyAbn = defaultHeader && defaultHeader.abn ? defaultHeader.abn : "";
            pageBrandingInfo.companyAddress = defaultHeader && defaultHeader.address ? defaultHeader.address : "";
            pageBrandingInfo.merchantLogo = defaultHeader && defaultHeader.companyLogo && defaultHeader.companyLogo.logoImage ? defaultHeader.companyLogo.logoImage : "";
            pageBrandingInfo.cobrandLogo = defaultHeader && defaultHeader.partnerLogo && defaultHeader.partnerLogo.logoImage ? defaultHeader.partnerLogo.logoImage : "";
            pageBrandingInfo.companyEmail = defaultHeader && defaultHeader.companyEmail ? defaultHeader.companyEmail : "";
            pageBrandingInfo.companyName = defaultHeader && defaultHeader.companyName ? defaultHeader.companyName : "";
            pageBrandingInfo.companyPhone = defaultHeader && defaultHeader.phone ? defaultHeader.phone : "";
            pageBrandingInfo.companyWeb = defaultHeader && defaultHeader.website ? defaultHeader.website : "";
          })
        }
        this.pageBranding=pageBrandingInfo
      });
    }

    const color =
      this.navbarColor === "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility("horizontal");
    if(Object.keys(this.paymentRequestDetail).length > 0)
      this.getBrandInGlobalSettings(this.paymentRequestDetail.partnerId._id);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}
</style>