<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.fullName">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.fullName }}</p>
    </div>
    <vs-dropdown class="cursor-pointer">
      <div class="con-img ml-3">
        <img key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block">
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'admin-profile' }).catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Profile</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/admin/change-password').catch(() => {})">
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Change Password</span>
          </li>
          <vs-divider class="m-1"/>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { clearLocalStorage } from "../../../../helper/localStorage.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },

  methods: {
    ...mapActions("admin", ["logoutAdmin"]),

    logout() {
      if (localStorage.getItem("accessToken")) {
        $cookies.remove("rememberMe");
        $cookies.remove("rememberMeToken");
        this.$vs.loading();
        this.logoutAdmin()
          .then(response => {
            this.$vs.loading.close();
            if (response) {
              clearLocalStorage();
              this.$router.push("/admin/login").catch(() => {});
            }
          })
          .catch(ex => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: ex.message,
              icon: "icon-alert-circle",
              iconPack: "feather",
              color: "Danger"
            });
          });
      }
    }
  }
};
</script>
